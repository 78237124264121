nav.sidebar, button#sidebar-collapse, div.social-btn-group, div.chapter-followup, div.footer {
  display: none!important;
}
body {
  font: 12pt "Gentium Book Basic", Georgia, "Times New Roman", Times, serif!important;
  line-height: 1.3!important;
  max-width: 40rem;
  margin: 0 auto;
}
img {
  max-width: 100%!important;
}
h1.title {
  font-size: 2.488em!important;
}
article:after {
  content: "Find more free data resources at DataSchool.com";
}
