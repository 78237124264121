// This is the place to place Bootstrap's variables overrides.
// As example you can start by importing the _variables.scss file from one of the
// theme provided by https://bootswatch.com .

// Some customization of the container class to have a better display in the guide.
// Don't hesitate to remove it to have a standard Bootstrap behavior
$container-max-widths: (
  sm: 540px,
  md: 640px,
  lg: 720px,
  xl: 940px
) !default;

// Minty 4.3.1
// Bootswatch

//
// Color system
//
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f7f7f9 !default;
$gray-300: #eceeef !default;
$gray-400: #ced4da !default;
$gray-500: #aaa !default;
$gray-600: #888 !default;
$gray-700: #5a5a5a !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #FF7851 !default;
$orange:  #fd7e14 !default;
$yellow:  #FFCE67 !default;
$green:   #56CC9D !default;
$teal:    #20c997 !default;
$cyan:    #6CC3D5 !default;

// setting colors from variables, some customization
// $primary:           $blue !default;
$primary:           #3c97da;
$primary-light:     #dbeefd;
// $secondary:         $indigo !default;
$secondary:         #fbc657;
$secondary-light:   #fff3db;
$secondary-dark:    #F3B42F;
$success:           $green !default;
$info:              $cyan !default;
$warning:           $yellow !default;
$danger:            $red !default;
$light:             $gray-100 !default;
$dark:              $gray-800 !default;
$text-color:        $gray-900;
$background-color:  $white;

$yiq-contrasted-threshold: 250 !default;

// Body

$body-color:                $gray-600 !default;

// Components

$border-radius:               .4rem !default;
$border-radius-lg:            .6rem !default;
$border-radius-sm:            .3rem !default;

// Fonts

// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,400i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Gentium+Book+Basic:400,400i,700,700i&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:700&display=swap');

$serif-font-loadout:          "Gentium Book Basic", "Times New Roman", serif;
$sans-font-loadout:           "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif;
$mono-font-loadout:           "Roboto Mono", monospace;

$headings-font-family:        $sans-font-loadout;
// $headings-color:              $gray-800 !default;
$headings-color:              $gray-800 !default;

// Tables

$table-border-color:          rgba(0,0,0,0.05) !default;

// Dropdowns

$dropdown-link-hover-color:         $white !default;
$dropdown-link-hover-bg:            $secondary !default;

// Navbar

$navbar-dark-color:                 rgba($white,.6) !default;
$navbar-dark-hover-color:           $white !default;

$navbar-light-color:                rgba($black,.3) !default;
$navbar-light-hover-color:          $gray-700 !default;
$navbar-light-active-color:         $gray-700 !default;
$navbar-light-disabled-color:       rgba($black,.1) !default;

// Pagination

$pagination-color:                  $white !default;
$pagination-bg:                     $primary !default;
$pagination-border-color:           $primary !default;

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $secondary !default;
$pagination-hover-border-color:     $pagination-hover-bg !default;

$pagination-active-bg:              $secondary !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         $white !default;
$pagination-disabled-bg:            #CCE8E0 !default;
$pagination-disabled-border-color:  $pagination-disabled-bg !default;

// Breadcrumbs

$breadcrumb-bg:                     $primary !default;
$breadcrumb-divider-color:          $white !default;
$breadcrumb-active-color:           $breadcrumb-divider-color !default;
