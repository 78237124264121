// the page wrapper, containers and sidebar
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    nav.sidebar {
      min-width: 250px;
      max-width: 250px;
      border-right: 1px solid $gray-400;
      background: $background-color;
      padding: 0;
      .sidebar-logo {
        margin: 0.75rem;
      }
      .sidebar-logo {
        padding: 0 0.5rem;
        display: block;
        text-align: center;
        opacity: 1;
        &:hover {
          opacity: 0.85;
        }
      }
      .book-title-links {
        display: flex;
        div {
          margin-right: 0.75rem;
          a {
            display: block;
            margin: 0;
          }
        }
        a.smallbook-link {
          flex: 0 0 3rem;
          margin: 0 0.5rem 0 0.75rem;
        }

        a.book-title {
          color: $text-color;
          font-weight: bold;
        }
        a.book-download {
          font-size: 0.8em;
        }
      }
      ul.components.main-nav > li, ul.components li.book-section > ul > li {
        padding: 0.25rem 0.75rem 0.25rem 0.75rem;
        border-right: 0.25rem solid $background-color;
        background: $background-color;
        &.active {
          border-color: $secondary;
          > a, a[aria-expanded="true"] {
          }
        }
      }
      ul.components {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid $gray-400;
        &.main-nav {
          padding-bottom: 0.5rem;
        }
        li {
          padding: 0;
          a {
            color: $text-color;
            display: block;
          }
          &.book-section {
            a.dropdown-toggle {
              position: relative;
              padding: 0.5rem 0.75rem;
              display: block;
              font-size: 0.833em;
              text-transform: uppercase;
              font-weight: 900;
              color: $gray-600;
              &::after {
                float: right;
                margin-top: 0.6em;
                // transform: rotate(0deg);
                transition: transform 0.25s;
              }
              &.collapsed::after {
                transform: rotate(180deg);
                transition: transform 0.25s;
              }
            }
            ul {
              padding-left: 0;
              ul {
                list-style: none;
                li {
                  padding: 0.25rem 0.75rem;
                  font-size: 0.833em;
                  &:first-child {
                    padding-top: 0.5rem;
                  }
                  &:last-child {
                    padding-bottom: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
      ul.list-unstyled.components:last-child {
        padding: 0 0 0.5rem;
      }
    }
    .sidebar, .content {
      position: absolute;
      top: 0;
      bottom: 0;

      overflow-y: auto;
    }
    .sidebar, .content, #sidebar-collapse {
      transition: left 0.25s, opacity 0.25s;
    }
}
.sidebar {
  left: -250px;
}
.content > .container {
  margin-top: 1rem;
}
@media only screen and (min-width: 30rem) {
  .content > .container {
    margin-top: 2rem;
  }
}
.content {
  left: 0;
  width: 100vw;
  .content-wrapper {
    border: 6px solid $white;
  }
  &.community-story-content {
    .content-wrapper {
      border: 0.25rem solid $primary-light;
    }
  }
  &.under-construction-content {
    .content-wrapper {
      border: 0.25rem solid $secondary-light;
    }
  }
}
#sidebar-collapse {
  position: fixed;
  top: 0.5rem;
  left: 0;
  z-index: 100;
  background: none;
  padding: 0.5em 0.75em 0.5em 1em;
  background: $gray-300;
  border-radius: 0 0.25rem 0.25rem 0;
  opacity: 0.6;
  transition: opacity 0.25s ease-in-out;
  &:hover {
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .sidebar {
    left: 0;
  }
  .content {
    left: 250px;
    right: 0;
    width: auto;
  }
  #sidebar-collapse {
    left: 250px;
  }
}
.wrapper.without-sidebar {
  .sidebar {
    left: -250px;
  }
  .content {
    left: 0;
  }
  #sidebar-collapse {
    left: 0;
  }
}
.wrapper.with-sidebar {
  .sidebar {
    left: 0;
  }
  .content {
    left: 250px;
    right: -250px;
  }
  #sidebar-collapse {
    left: 250px;
  }
}
@media (min-width: 768px) {
  .wrapper.with-sidebar {
    .sidebar {
      left: 0;
    }
    .content {
      left: 250px;
      right: 0;
    }
    #sidebar-collapse {
      left: 250px;
    }
  }
}

// sidebar collapse button
