html, body {
  height: 100%;
}
// MY STUFF TO MAKE 1 REM = 1 LINE HEIGHT
html {
  min-height: 100%;
  font-size: 150%;
  background: #F6F6F6;
  // @media(max-width: 57em) {
  //     font-size: 141%;
  // }
}
body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    font-size: 66.6667%;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.5;
    color: $text-color;
    background-color: $background-color;
}
/* setting the overflow scrolling to have momentum, for on mobile */
nav.sidebar, div.content {
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}
// TYPOGRAPHY
// type size reset
h1, h2, h3, h4, h5, h6, p.faux-header {
  margin: 2rem 0 1rem;
  line-height: 1.15;
  font-weight: 700;
}
h4, h5, h6 {
  color: $text-color;
}
h1.mega-title, h1.title {
  font-family: $serif-font-loadout;
}
h1.mega-title {
  font-size: 4.209em;
  font-style: italic;
}
h1.title {font-size: 3.157em;}
h1 {
  margin-top: 0;
  font-size: 2.369em;
}
h2, p.faux-header {font-size: 1.77em;}
h3 {font-size: 1.33em;}
h4 {font-size: 1.0em;}
h5 {font-size: .8em; font-style: italic;}
h6 {font-size: 0.833em; text-transform: uppercase;}
small, .small-font, .text_small {font-size: 0.833em;}

blockquote p {
  border-left: 5px solid $secondary;
  margin-left: -15px;
  padding-left: 10px;
  font-style: italic;
}
pre, pre.highlight, code {
  background: $gray-100;
  font-size: 0.83333em;
  font-weight: 400;
  border-radius: 0.125em;
}
pre {
  padding: 0.5rem;
  margin: 1rem 0;
  border: 1px solid $gray-400;
}
code {
  padding: 0.125em;
}
.CodeMirror-scroll {
  width: 100%;
}
kbd {
  padding: 0.125em 0.25em;
  border-radius: 0.25em;
}
em {
  margin-bottom: 1rem;
}
.bootstrap-table .fixed-table-container table.table {
  display: table;

  td, th, thead th .th-inner {
    padding: 0.5rem;
  }
}
table {
  display: block;
  overflow: auto;
  font-size: 0.8em;
  thead {
    width: 100%;
  }
  td, th {
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.25);
      line-height: 1rem;
      vertical-align: top;
      padding: 0.3rem 1rem 0.3rem 0;
  }
  tr th, tr:first-child td {
    border-top: 2px solid rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    font-weight: 700;
  }
}
.bootstrap-table .fixed-table-container table.table-bordered {
  border: none;
  margin: 0;
    td, th {
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.25);
      line-height: 1rem;
      vertical-align: top;
      // padding: 0.3rem 1rem 0.3rem 0;
    }
    th {
      border-top: 2px solid rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
      font-weight: 700;
    }
}
.bootstrap-table .fixed-table-container table.table-bordered tr:first-child td, thead + tbody tr:first-child td {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  text-transform: none;
  font-weight: 600;
}
section.page {
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
}
section.page-wide {
  max-width: none;
  margin: 0 auto;
  padding: 3rem 0 0;
}
article, .home-hero {
  font-size: 1.33em;
}
article {
  margin-bottom: 2rem;

  p, li {
    font-family: $serif-font-loadout;
    font-weight: 400;
  }
  ul, ol, dl {
    padding-left: 1rem;
  }
}

a {
  text-decoration: underline;
}
a.btn {
  text-decoration: none;
}
nav.sidebar, .chapter-info, ul.contributors-list {
  a {
    text-decoration: none;
  }
}

.btn {
  font-weight: 600;
  border-radius: 0.25em;
  font-size: 1em;
}
.btn {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn.btn-default {
  background: $gray-200;
  border-color: $gray-400;
  &:hover {
    background: $gray-300;
  }
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
p img {
  margin: 2rem auto;
}
li p img {
  margin: 1rem auto 2rem;
}
article.img-border-on-default img, img.img-border {
  border: 1px solid $gray-400;
}
article.img-border-on-default img.img-no-border {
  border: none;
}
// CUSTOM UTILITY CLASSES
.centered {
  text-align: center;
}
.height-3 {
  height: 3rem;
}
.d-none {
  display: none;
}
ul.no-pad-list {
  padding-left: 0;
}
ul.light-pad-list {
  padding-left: 0.75rem;
}
