// the angled home hero layout with grid background
.home-hero {
  position: relative;
  padding: 0 0 5rem;
  &::before {
    content: '';
    background-image: url('/assets/images/dot-grid.png');
    background-position: 0 0;
    background-size: 17px;
    background-attachment: scroll;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 100px), 0% 100%);
  }
  > div {
    position: relative;
    z-index: 1;
  }
  h1 .logo {
    height: 6rem;
  }
}
.book-cover {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: rotate(4deg);
  img {
    box-shadow: 2px 2px 10px 0 rgba(0,0,0,.2), 0 4px 25px 0 rgba(0,0,0,.2);
    width: 100%;
    max-width: 14rem;
  }
}
.book-info, .chapter-info {
  &:hover {
    text-decoration: none;
  }
  h2, h3 {
    margin-top: 0.5rem;
    i {
      font-size: 0.8em;
      color: $primary;
      opacity: 0.25;
    }
    &:hover {
      i {
        opacity: 1;
      }
    }
  }
  p {
    color: $text-color;
  }
  em.from-book, p {
    display: block;
    margin-bottom: 0.5rem;
  }
}
.chapter-thumbnail-img {
  display: inline-block;
  margin: 1rem 0 0;
  background-position: 50% 50%;
  height: 8rem;
  width: 100%;
  background-size: cover;
  border: 1px solid $gray-400;
}
.book-index-chapter .chapter-thumbnail-img {
  height: 6rem;
}
.book-list {
  list-style: none;
  li {
    margin-bottom: 1rem;
    a {
      display: flex;
      align-items: flex-start;
      .description {
        flex-grow: 1;
        h2 {
          margin-top: 0;
        }
      }
    }
  }
}
@media only screen and (min-width: 30rem) {
  .book-list li a {
    flex-direction: row;
  }
}
.contributors-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  li {
    display: inline-block;
    padding: 0.5rem;
    min-height: 13rem;
    h2, h3 {
      font-size: 1em;
    }
    h2 {
      margin: 1rem 0 0.25rem;
    }
    h3 {
      margin: 0.5rem 0 1rem;
      font-size: 0.833em
    }
    .author-img {
      border-radius: 0.25rem;
      width: 100%;
      height: 8rem;
      background-position: center;
      background-size: cover;
      background-color: #fff;
    }
  }
}
@media only screen and (min-width: 25rem) {
  .contributors-list {
    flex-direction: row;
    li {
      width: 50%;
    }
  }
}
@media only screen and (min-width: 48rem) {
  .contributors-list {
    li {
      width: 33.3333%;
    }
  }
}
.hover-link {
  .hover-img {
    position: relative;
    bottom: 0;
    transition: bottom 0.25s ease-in-out;
  }
  &:hover {
    .hover-img {
      bottom: 0.25rem;
    }
  }
}
.book-list li a img {
  width: 6rem;
  margin-left: 1rem;
}
.contributor-info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contributor-headshot {
  width: 12rem;
  border-radius: 0.25rem;
}
@media only screen and (min-width: 30rem) {
  .contributor-info {
    flex-direction: row;
  }
  .contributor-headshot {
    margin-left: 1rem;
  }
}
.story-author-blurb {
  display: flex;
  img.contributor-headshot {
    width: 8rem;
    height: 100%;
    margin: 0 1rem 0 0;
  }
}

.book-title {
  margin-bottom: 5rem;
}
a.header-link {
  font-size: 0.6em;
  margin-right: 0.25em;
  opacity: 0.25;
  transition: opacity 0.25s ease-in-out;
  &:hover {
    opacity: 1;
  }
}
.give-feedback-btn, .next-chapter-btn {
  display: block;
  margin-top: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.under-construction-blurb {
  background: $secondary-light;
  margin: 0 -1rem;
  padding: 1rem;
  border-radius: 0.25rem;
  h2 {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.chapter-story-intro-blurb {
  background: $primary-light;
  margin: 0 -1rem 2rem;
  padding: 1rem;
  border-radius: 0.25rem;
  h2 {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

/* styles for Hubspot forms */
div.chapter-followup form.hs-form, .homepage-signup form.hs-form {
  padding: 1rem;
}
form.hs-form {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  grid-column-gap: 0;
  grid-row-gap: .5rem;
  font-family: 'open sans', sans-serif;
  font-weight: 600;
  border-radius: 0.25rem;
  span.hs-form-required {
    display: none;
  }
  ul.no-list {
    list-style: none;
    color: $gray-600;
    font-size: 0.833em;
    margin-top: 0.25rem;
  }
  ul.no-list.hs-error-msgs.inputs-list {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .hs-submit {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 1;
  }
  div.hs-email .input {
      display: inline-block;
      width: 100%;
  }
  div.hs-email input.hs-input {
      width: 100%;
      background: #fff;
      border: 1px solid #1c6497;
      padding: 0.382rem 0.618rem;
      border-radius: 0.25em 0 0 0.25em;
  }
  .legal-consent-container {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 2;
      text-align: center;
      font-size: 0.833em;
      margin-top: .5rem;
      p {
          margin: 0;
          max-width: none;
          font-size: 12px;
          color: #999;
      }
      ul {
        list-style: none;
        margin-bottom: 0;
      }
  }
  > div:not([class])[data-reactid*="hbspt-forms"] {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 3;
    text-align: center;
    font-size: 0.833em;
    p {
        margin: 0;
        max-width: none;
        font-size: 12px;
        color: #999;
        padding-left: 1rem;
        margin-bottom: 1rem;
    }
  }
  input.hs-button.primary.large {
      padding: 0.382rem 0.618rem;
      font-weight: 600;
      color: #fff;
      background-color: #206595;
      border-color: #206595;
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      font-size: 0.8rem;
      line-height: 1.26;
      border-radius: 0 0.25em 0.25em 0;
      cursor: pointer;
  }
}

.social-btn-group {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 0;
  a {
    padding: 0.5rem 0.5rem 0.3rem 0.5rem;
    width: 2rem;
    text-align: center;
    border-radius: 0.25rem;
    opacity: 0.3;
    transition: opacity 0.25s ease-in-out;
    &:hover {
      opacity: 1;
      background: $gray-300;
    }
    i {
      font-size: 1em;
    }
  }
}
.chapter-followup .centered, .homepage-signup .centered {
    background: $gray-200;
    padding: 1rem 1rem 0;
    p.faux-header, h2 {
      margin: 0.5rem 0;
    }
}
.chapter-followup .centered {
  margin: 0 -1rem 2rem;
}
.footer-links {
  margin-bottom: 2rem;
  h4 {
    margin-bottom: 0.125rem;
  }
}
