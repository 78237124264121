// SQL BOX CSS

sqlbox {
  display: block;
  max-width: 28rem;
  margin: 0 auto;
  font-size: 0.833em;

  margin-bottom: 1rem;
  .result .pagination-detail {
    display: none;
  }
  .sqlerror {
    padding: 0.5rem 0.75rem;
    background-color: $secondary-light;
    color: $text-color;
    border-radius: 5px;
    font-family: $mono-font-loadout;
    .error-help {
      display: inline-block;
      margin-top: 0.5rem;
      font-size: 0.83333em;
      font-weight: 600;
      text-align: right;
      color: $primary;
      border-color: $secondary;
      font-family: $sans-font-loadout;
      &:hover {
        color: $text-color;
        background: $secondary;
      }
    }
  }
  table {
    background-color: $body-color;
    border-radius: 5px;
    font-size: 1em;
    th {
      text-transform: none;
    }
  }
  div.noanswer {
    display: none;
  }
  div.answered-checkbox {
    float: left;
    margin-left: -55px;
  }
  th:not(.table-title) { text-transform: none; }
  .correct {
    background-color: #e4ffef;
  }
  .incorrect {
    background-color: #f99;
  }
  .result-message {
    text-align: right;
    font-style: italic;
    padding: 0.5rem 0;
    display: none; // hidden by default.
  }
  .CodeMirror {
    font-family: $mono-font-loadout;
    margin-bottom: 1rem;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    overflow-x: auto;
  }
  .hint {
    display: none;
  }
  input.btn {
    margin-bottom: 0;
  }
    .sqlbox {
      input.run-sql {
        margin-bottom: 1rem;
        font-size: 0.833em;
        font-weight: 700;
      }
  }
  .fixed-table-pagination div.pagination {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .loading-spinner {
    display: none;
    height: 40px;
  }
  img {
    margin: 0;
  }
  form.sqlbox {
    padding: 0 0.5rem 0.5rem;
    margin: 0 -0.5rem;
    border-radius: 5px;
  }
  form.loading {
    .loading-spinner {
      display: block;
    }
    input.run-sql {
      display: none;
    }
  }
}
.result {
  .table-hover tbody tr:hover {
    background: $secondary-light;
  }
  .page-item {
    a.page-link {
      background: $background-color;
      color: $text-color;
      font-family: $sans-font-loadout;
      font-weight: 600;
      border-color: $gray-400;
      &:hover {
        background: $secondary-light;
      }
    }
    &.active {
      .page-link {
        background: $secondary;
        color: $text-color;
        border-color: $secondary-dark;
      }
    }
    &.disabled {
      .page-link {
        background: $gray-200;
        color: $text-color;
      }
    }
  }
}

.quizbox {
  h3.question {
    span.q {
      margin-left: -23px;
    }
    small {
      text-transform: uppercase;
      margin-left: 5px;
      display: block;
    }
  }

}

// CSS for tutorials sidebar navigation
.sql-categories {
  & > li {
    margin-bottom: 0.25rem;
  }
  ol {
    display: none;
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  li.current-level {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a.level-label {
      color: $primary;
    }
    ol {
      display: block;
    }
  }
  a.level-label {
    cursor: pointer;
  }
}

// scroll fix 
div.CodeMirror {
  &-hscrollbar {
    overflow-x: hidden !important;
  }

  &-scroll {
    overflow: hidden !important;
  }
}